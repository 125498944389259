import './Tour.css';
import React, { useState } from 'react';

const Tour = () => {

    const [showCurrentList, setCurrentList] = useState(true);
    const [isActive, setIsActive] = useState(true);

    const toggleCurr = (val) => {
        setIsActive(!isActive);
        return setCurrentList(val);
    }

    return(
        <div className="page" id="tour">
            <h1 className="page-header">Shows</h1>

            <div className="inner-content">
                <div className="flex row f-sa tourtype">
                    <h1 className={isActive ? 'active' : ''} onClick={() => toggleCurr(true)}>Upcoming</h1>
                    <p style={{margin: '0 20px'}}>/</p>
                    <h1 className={!isActive ? 'active' : ''} onClick={() => toggleCurr(false)}>Past</h1>
                </div>

                {showCurrentList ? (
                <div className="flex col f-sa">
                    <div className="content-full">
                        <ul className="header-list">
                            <li>
                                <div className="date header">
                                    DATE
                                </div>
                                <div className="location header">
                                    LOCATION
                                </div>
                                <div className="tickets header">
                                    TICKETS
                                </div>
                                <div className="venue header">
                                    VENUE
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div className="date">
                                    02/08/25
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Hard Luck
                                </div>
                            </li>
                            <li>
                                <div className="date">
                                    02/16/25
                                </div>
                                <div className="location">
                                    Waterloo, Ont
                                </div>
                                <div className="tickets">
                                    <a href="https://www.ticketscene.ca/venues/5642/" target="new">Tickets</a>
                                </div>
                                <div className="venue">
                                    Revive
                                </div>
                            </li>
                            <li>
                                <div className="date">
                                    02/22/25
                                </div>
                                <div className="location">
                                    Hamilton, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Vertagogo
                                </div>
                            </li>
                            <li>
                                <div className="date">
                                    04/14/25
                                </div>
                                <div className="location">
                                    East York, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Linsmore Tavern
                                </div>
                            </li>
                            {/* <li>
                                <div className="date">
                                    12/00/24
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    TBD
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div> 
                ) : (
                <div className="flex col f-sa">
                    <div className="content-full">
                        <ul className="header-list">
                            <li>
                                <div className="date header">
                                    DATE
                                </div>
                                <div className="location header">
                                    LOCATION
                                </div>
                                <div className="tickets header">
                                    TICKETS
                                </div>
                                <div className="venue header">
                                    VENUE
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li className="past-date">
                                <div className="date">
                                    05/18/24
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Duffy's Tavern
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    06/21/24
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Duffy's Tavern
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    08/10/24
                                </div>
                                <div className="location">
                                    Guelph, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Red Papaya
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    08/24/24
                                </div>
                                <div className="location">
                                    Etobicoke, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    The Rockpile
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    09/26/24
                                </div>
                                <div className="location">
                                    Etobicoke, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    The Rockpile
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    10/11/24
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Primal Note Studios
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    10/12/24
                                </div>
                                <div className="location">
                                    Newmarket, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    The Spot
                                </div>
                            </li>
                            <li className="past-date">
                                <div className="date">
                                    10/25/24
                                </div>
                                <div className="location">
                                    Toronto, Ont
                                </div>
                                <div className="tickets">
                                    N/A
                                </div>
                                <div className="venue">
                                    Hard Luck
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                )}

                
            </div>
        </div>
    );
}

export default Tour;